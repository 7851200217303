import React from "react";
import HeroSection from "./sections/HeroSection";
import BenefitsSection from "./sections/BenefitsSection";
import HowItWorksSection from "./sections/HowItWorksSection";
import TestimonialSection from "./sections/TestimonialSection";
import PricingSection from "./sections/PricingSection";
import FAQSection from "./sections/FAQSection";
import CTASection from "./sections/CTASection";
import Footer from "./sections/Footer";

const Home = () => (
  <>
    <HeroSection />
    <BenefitsSection />
    <HowItWorksSection />
    {/* <TestimonialSection />
    <PricingSection /> */}
    <FAQSection />
    <CTASection />
    <Footer />
  </>
);

export default Home;

import React from "react";
import { Container, Accordion } from "react-bootstrap";

const FAQSection = () => (
  <section id="faq" className="faq-section py-5">
    <Container>
      <h2 className="text-center mb-5">Frequently Asked Questions</h2>
      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            What makes Laio different from other AI tools?
          </Accordion.Header>
          <Accordion.Body>
            Laio is designed by journalists, for journalists, focusing on
            maintaining editorial integrity. We tested and optimized the process
            it takes to generate an article for over a year until we reached the
            point that we were confident enough to make it available for
            everyone.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>
            How can Laio generate a specific writing style?
          </Accordion.Header>
          <Accordion.Body>
            You can choose between different profiles for specific writing
            styles. We develop these profiles ourselves and continue updating
            them to ensure the best possible writing.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>Can I use Laio as an individual?</Accordion.Header>
          <Accordion.Body>
            Soon! We are currently working on making Laio better and available
            for everyone!
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>Is Laio Assistant Reliable?</Accordion.Header>
          <Accordion.Body>
            We can't guarantee 100% automatic reliability, that's where the
            journalist comes in. We provide you with relevant sources and it is
            up to you to choose which sources you want to include in your
            article.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4">
          <Accordion.Header>
            Do you organize workshops around Laio?
          </Accordion.Header>
          <Accordion.Body>Only on request.</Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="5">
          <Accordion.Header>Does Laio comply with GDPR?</Accordion.Header>
          <Accordion.Body>
            Yes, although we use AI models of non-European companies, all your
            input is anonymized by us.
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </Container>
  </section>
);

export default FAQSection;

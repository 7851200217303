import React, { useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  moveNextStep,
  expandInput,
  getSearchWords,
  setPrompt,
  updateTotalPromptTokens,
  updateTotalCompletionTokens,
} from "../../actions/article";
import LoadingIndicator from "./LoadingIndicator";
import { Button } from "react-bootstrap";

const Input = ({ user }) => {
  const dispatch = useDispatch();
  const prompt = useSelector((state) => state.article.formData.prompt); // Get prompt from Redux state
  const promptRef = useRef();
  const [loading, setLoading] = useState(false); // State to track loading state
  const [error, setError] = useState(null); // State to track errors
  const orgId = user ? user.orgId : null;
  const language = user ? user.language : null;
  const current_profile = user ? user.current_profile : null;

  const handleNextStep = async () => {
    setLoading(true); // Set loading state to true before API call
    setError(null); // Clear any previous errors
    const currentPrompt = promptRef.current.value;

    try {
      const expandedData = await dispatch(
        expandInput(currentPrompt, orgId, current_profile, language)
      );
      console.log(expandedData);

      // Check for "Title not found" in the result array
      const problematicUrl = expandedData.result.find(
        (item) => item.title === "Title not found"
      );

      if (problematicUrl) {
        setError(
          `An error occured while trying to get this URL: <br/> ${problematicUrl.url} <br/> Please try a different source`
        );
        setLoading(false); // Set loading state to false
        return;
      }

      const summaries = expandedData.result;
      const searchWordsResponse = await dispatch(
        getSearchWords(
          currentPrompt,
          summaries,
          orgId,
          current_profile,
          language
        )
      );
      let totalCompletionTokens =
        expandedData.metadata.completionTokens +
        searchWordsResponse.metadata.completionTokens;
      let totalPromptTokens =
        expandedData.metadata.promptTokens +
        searchWordsResponse.metadata.promptTokens;
      dispatch(updateTotalPromptTokens(totalPromptTokens));
      dispatch(updateTotalCompletionTokens(totalCompletionTokens));
      dispatch(moveNextStep({ prompt: currentPrompt }));
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("An unexpected error occurred. Please try again.");
    } finally {
      setLoading(false); // Set loading state to false after API call completes
    }
  };

  const handleInputChange = () => {
    dispatch(setPrompt(promptRef.current.value)); // Update the prompt in Redux state when input changes
  };

  return (
    <div className='step-content dashboard-input-wrapper step-1'>
      {loading ? ( // Display loader when loading state is true
        <div className='dotloader-wrapper'>
          <LoadingIndicator text='Generating search words' />
        </div>
      ) : (
        <>
          <div className='dashboard-input-container'>
            <h4 className='instructions'>
              Describe what you want your article to be about, include at least
              one URL
            </h4>
            <hr />
            <textarea
              rows={5}
              ref={promptRef}
              className='form-control'
              placeholder='Enter your instructions'
              onChange={handleInputChange}
            />
            {error && (
              <div className='error-message'>
                <div className='icon'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 24 24'
                    fill='none'
                    stroke='currentColor'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    className='feather feather-alert-circle'
                  >
                    <circle cx='12' cy='12' r='10'></circle>
                    <line x1='12' y1='8' x2='12' y2='12'></line>
                    <line x1='12' y1='16' x2='12.01' y2='16'></line>
                  </svg>
                </div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: error.replace(/\n/g, "<br/>"),
                  }}
                />
              </div>
            )}
          </div>

          <div className='step-nav input-button'>
            <Button variant='primary' onClick={handleNextStep}>
              Next Step
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default Input;

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { getArticle } from "../actions/article";

const ArticlePage = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const formData = useSelector((state) => state.article.article);
  const { title, intro, body, prompt, profile } = formData;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(getArticle(id)).then(() => setLoading(false));
  }, [dispatch, id]);

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(
        `${title}\n${intro}\n${body
          .map(
            (paragraph) =>
              `${paragraph.paragraphtitle}\n${paragraph.paragraphtext}`
          )
          .join("\n")}`
      );
      alert("Article copied to clipboard!");
    } catch (error) {
      console.error("Failed to copy article to clipboard:", error);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className='article'>
      <div className='dashboard-input-container finished-article-body'>
        <button
          className='copy-button btn btn-primary'
          onClick={copyToClipboard}
        >
          Copy Article
        </button>
        <h1>{title}</h1>
        <div
          className='article-intro'
          dangerouslySetInnerHTML={{ __html: intro }}
        />
        {body.map((paragraph, index) => (
          <div key={index} className='article-paragraph'>
            <h2>{paragraph.paragraphtitle}</h2>
            <p dangerouslySetInnerHTML={{ __html: paragraph.paragraphtext }} />
          </div>
        ))}
        <div className='article-sources'>
          <h2>Sources:</h2>
          <ul>
            {Object.keys(body[body.length - 1]).map(
              (sourceKey, index) =>
                sourceKey.startsWith("source") && (
                  <li key={index}>
                    <a
                      href={body[body.length - 1][sourceKey]}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      {body[body.length - 1][sourceKey]}
                    </a>
                  </li>
                )
            )}
          </ul>
        </div>
      </div>

      <div className='dashboard-input-container'>
        <h3 className='article-settings__prompt'>Prompt used:</h3>
        <p className='prompt'>{prompt}</p>
        <h3 className='article-settings__profile'>Profile used:</h3>
        <p className='prompt'>{profile}</p>
      </div>
    </div>
  );
};

export default ArticlePage;

import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import LoadingIndicator from "./LoadingIndicator";
import {
  moveNextStep,
  movePreviousStep,
  writeTitleIntro,
  saveMoreInfoAnswers,
  updateTotalCompletionTokens,
  updateTotalPromptTokens,
} from "../../actions/article";
import { Button } from "react-bootstrap";

const Review = ({ user }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false); // State for loading indicator
  const [answers, setAnswers] = useState({});
  const formData = useSelector((state) => state.article.formData);
  const {
    prompt,
    summaries,
    more_info_questions,
    totalPromptTokens,
    totalCompletionTokens,
  } = formData;
  const orgId = user ? user.orgId : null;
  const language = user ? user.language : null;
  const current_profile = user ? user.current_profile : null;

  const handleInputChange = (questionId, event) => {
    const { value } = event.target;
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionId]: value,
    }));
  };

  const handleNextStep = async () => {
    setLoading(true); // Set loading state to true
    try {
      // Dispatch action to write title and intro
      const writeTitleIntroResponse = await dispatch(
        writeTitleIntro(
          prompt,
          answers,
          more_info_questions,
          summaries,
          orgId,
          current_profile,
          language
        )
      );

      let newTotalCompletionTokens =
        writeTitleIntroResponse.metadata.completionTokens +
        totalCompletionTokens;
      let newTotalPromptTokens =
        writeTitleIntroResponse.metadata.promptTokens + totalPromptTokens;
      dispatch(updateTotalPromptTokens(newTotalPromptTokens));
      dispatch(updateTotalCompletionTokens(newTotalCompletionTokens));
      // Dispatch action to save more_info_answers
      dispatch(saveMoreInfoAnswers(answers));
      // Move to the next step
      dispatch(moveNextStep());
    } catch (error) {
      console.error("Error moving to next step:", error);
    } finally {
      setLoading(false); // Set loading state to false
    }
  };

  return (
    <div className="step-content dashboard-input-wrapper step-4">
      {loading ? ( // Render loader if loading is true
        <div className="dotloader-wrapper">
          <LoadingIndicator text="Writing the title and intro" />
        </div>
      ) : (
        <>
          <div className="dashboard-input-container">
            <h4 className="instructions">
              You can provide some more information about the article here, or
              you can skip to the next step.
            </h4>
            <hr />
            {more_info_questions.map((question, index) => (
              <div key={index} className="question-container">
                <h4>Context:</h4>
                <p>{question.context}</p>
                <h4>Question:</h4>
                <p>{question.question}</p>
                <textarea
                  id={`answer-${index}`}
                  value={answers[index] || ""}
                  onChange={(e) => handleInputChange(index, e)}
                  placeholder="Provide more information here"
                />
              </div>
            ))}
          </div>

          <div className="step-nav">
            <Button variant="primary" onClick={movePreviousStep}>
              Back
            </Button>
            <Button variant="primary" onClick={handleNextStep}>
              Next Step
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default Review;

import React from "react";
import { Container, Button } from "react-bootstrap";

const CTASection = () => (
  <section className="cta-section py-5 bg-primary text-white text-center">
    <Container>
      <h2 className="mb-4">Ready to Elevate Your Journalism?</h2>
      <p className="lead mb-4 text-white">
        Request access to Laio and see how it can transform your workflow.
      </p>
      <Button
        href="mailto:info@laio.pro?subject=Request%20for%20Laio%20Account&body=Hello%20Laio%20team%2C%0A%0AI%20would%20like%20to%20request%20an%20account%20to%20try%20Laio.%20Please%20provide%20me%20with%20more%20information%20about%20getting%20started.%0A%0AThank%20you%2C%0A%5BYour%20Name%5D"
        variant="light"
        size="lg"
        className="btn-hover-effect btn-white"
      >
        Request Account
      </Button>
    </Container>
  </section>
);

export default CTASection;

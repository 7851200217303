import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  OrgsClearMessages,
  fetchOrganizations,
  createOrganization,
  deleteOrganization,
  // fetchAndSaveProfiles,
  fetchProfiles,
  updateOrganizationProfiles,
  fetchOrganizationProfiles,
} from "../actions/organizations";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { createUser } from "../actions/user";

const Admin = ({
  fetchOrganizations,
  createOrganization,
  deleteOrganization,
  createUser,
}) => {
  const [orgName, setName] = useState("");
  const [orgId, setOrgId] = useState("");
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const profiles = useSelector((state) => state.organizations.profiles);
  const organizationProfiles = useSelector(
    (state) => state.organizations.organizationProfiles
  );
  const organizations = useSelector(
    (state) => state.organizations.organizations
  );
  const [selectedProfiles, setSelectedProfiles] = useState([]);
  const [selectedOrganization, setSelectedOrganization] = useState(null);

  useEffect(() => {
    dispatch(fetchProfiles());
    fetchOrganizations();
  }, [dispatch]);

  useEffect(() => {
    if (selectedOrganization) {
      dispatch(fetchOrganizationProfiles(selectedOrganization));
    }
  }, [dispatch, selectedOrganization]);

  useEffect(() => {
    setSelectedProfiles(organizationProfiles.map((profile) => profile.id));
  }, [organizationProfiles]);

  const handleDeleteOrganization = (id) => {
    if (window.confirm("Are you sure you want to delete this organization?")) {
      deleteOrganization(id).then(() => fetchOrganizations());
    }
  };

  const handleInviteUserSuper = (e) => {
    e.preventDefault();
    createUser(email, orgId);
    setEmail("");
    setOrgId("");
  };

  const handleCreateOrganization = (e) => {
    e.preventDefault();
    const slug = orgName.toLowerCase().split(" ").join("-");
    const orgData = {
      name: orgName,
      slug: slug,
    };
    createOrganization(orgData).then(() => fetchOrganizations());
  };

  // const handleFetchAndSaveProfiles = () => {
  //   dispatch(fetchAndSaveProfiles());
  //   dispatch(fetchProfiles());
  // };

  const handleProfileChange = (profileId) => {
    setSelectedProfiles((prevSelectedProfiles) => {
      if (prevSelectedProfiles.includes(profileId)) {
        return prevSelectedProfiles.filter((id) => id !== profileId);
      } else {
        return [...prevSelectedProfiles, profileId];
      }
    });
  };

  const handleSave = () => {
    dispatch(
      updateOrganizationProfiles(selectedOrganization, selectedProfiles)
    );
  };

  return (
    <>
      {" "}
      <div className='dashboard-input-wrapper admin-wrapper'>
        <ToastContainer />
        <div className='dashboard-input-container'>
          <h1>Organizations</h1>
          <form onSubmit={handleCreateOrganization}>
            <div className='input-group mb-3'>
              <input
                type='text'
                className='form-control'
                placeholder='Name'
                value={orgName}
                onChange={(e) => setName(e.target.value)}
              />
              <button className='btn btn-primary' type='submit'>
                Add
              </button>
            </div>
          </form>
          <table className='table table-hover'>
            <thead>
              <tr>
                <th className='organization-name' scope='col'>
                  Name
                </th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(organizations) &&
                organizations.map((org) => (
                  <tr
                    key={org.id}
                    onClick={() =>
                      navigate(`/dashboard/admin/organization/${org.id}`)
                    }
                  >
                    <td className='organization-name'>{org.name}</td>
                    <td>
                      <button
                        className='delete-button'
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDeleteOrganization(org.id);
                        }}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <div className='dashboard-input-container'>
          <h2>Invite Users to Organizations</h2>
          <form onSubmit={handleInviteUserSuper}>
            <div className='form-group__wrapper mt-4'>
              <label>Email address</label>
              <input
                type='email'
                className='form-control'
                placeholder='Enter email'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className='form-group__wrapper mt-4'>
              <label>Organization</label>
              <select
                className='form-control'
                value={orgId}
                onChange={(e) => setOrgId(e.target.value)}
                required
              >
                <option value=''>Select Organization</option>
                {Array.isArray(organizations) &&
                  organizations.map((org) => (
                    <option key={org.id} value={org.id}>
                      {org.name}
                    </option>
                  ))}
              </select>
            </div>
            <div className='form-group__wrapper'>
              <button className='btn btn-primary mt-4' type='submit'>
                Invite
              </button>
            </div>
          </form>
        </div>
        {/* Select profiles for organizations */}
        <div className='dashboard-input-container'>
          <h2>Add Profiles to Organizations</h2>
          <div className='form-group__wrapper mt-4'>
            <label>Organization</label>
            <select
              value={selectedOrganization}
              onChange={(e) => {
                const selectedOrg = e.target.value;
                if (selectedOrg) {
                  setSelectedOrganization(selectedOrg);
                }
              }}
            >
              <option value=''>Select Organization</option>

              {Array.isArray(organizations) &&
                organizations.map((organization) => (
                  <option key={organization.id} value={organization.id}>
                    {organization.name}
                  </option>
                ))}
            </select>
          </div>
          <div className='form-group__wrapper mt-4'>
            <label>Profiles</label>
            {Array.isArray(profiles) &&
              profiles.map((profile) => (
                <div key={profile.id}>
                  <input
                    type='checkbox'
                    checked={selectedProfiles.includes(profile.id)}
                    onChange={() => handleProfileChange(profile.id)}
                  />
                  <label>{profile.name}</label>
                </div>
              ))}
          </div>
          <button className='btn btn-primary mt-4' onClick={handleSave}>
            Save
          </button>
        </div>
      </div>
      {/* <div className='dashboard-input-container'>
        <button
          className='btn btn-primary mt-4'
          onClick={handleFetchAndSaveProfiles}
        >
          Fetch and Save Profiles
        </button>
      </div> */}
    </>
  );
};

const mapStateToProps = (state) => ({
  organizations: state.organizations.organizations,
});

export default connect(mapStateToProps, {
  fetchOrganizations,
  createOrganization,
  deleteOrganization,
  createUser,
  // fetchAndSaveProfiles,
  fetchProfiles,
  updateOrganizationProfiles,
})(Admin);

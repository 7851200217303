import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  fetchProfiles,
  updateOrganizationProfiles,
  fetchOrganizations,
  deleteOrganization,
  createOrganization,
} from "../actions/organizations";
import { createUser } from "../actions/user";
import { Form, Table, Button, Dropdown, InputGroup } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";

const SuperAdmin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const profiles = useSelector((state) => state.organizations.profiles);
  const organizations = useSelector(
    (state) => state.organizations.organizationData
  );

  const [orgName, setOrgName] = useState("");

  const [inviteEmail, setInviteEmail] = useState("");
  const [selectedOrgId, setSelectedOrgId] = useState(null);
  const [selectedProfiles, setSelectedProfiles] = useState({});
  const [switchStates, setSwitchStates] = useState({});
  const [dateRange, setDateRange] = useState([new Date(), new Date()]); // Default to today
  const [startDate, endDate] = dateRange;

  useEffect(() => {
    dispatch(fetchProfiles());
    fetchOrganizationsWithDateRange();
  }, [dispatch]);

  useEffect(() => {
    if (startDate && endDate) {
      fetchOrganizationsWithDateRange();
    }
  }, [startDate, endDate]);

  const fetchOrganizationsWithDateRange = () => {
    const formattedStartDate = format(startDate, "yyyy-MM-dd");
    const formattedEndDate = format(endDate, "yyyy-MM-dd");

    console.log(
      "Fetching organizations with date range:",
      formattedStartDate,
      formattedEndDate
    );

    dispatch(fetchOrganizations(formattedStartDate, formattedEndDate));
  };

  useEffect(() => {
    if (organizations) {
      const profilesByOrg = organizations.reduce((acc, org) => {
        acc[org.organizationId] = org.profileNames || [];
        return acc;
      }, {});
      setSelectedProfiles(profilesByOrg);
    }
  }, [organizations]);

  const handleDeleteOrganization = (id) => {
    if (window.confirm("Are you sure you want to delete this organization?")) {
      dispatch(deleteOrganization(id)).then(() =>
        fetchOrganizationsWithDateRange()
      );
    }
  };

  const handleInvite = (email, organizationId) => {
    dispatch(createUser(email, organizationId));
  };

  const handleInviteSubmit = (e) => {
    e.preventDefault();
    handleInvite(inviteEmail, selectedOrgId);
  };

  const handleProfileSelect = (organizationId, profileName) => {
    setSelectedProfiles((prevSelectedProfiles) => {
      const orgProfiles = prevSelectedProfiles[organizationId] || [];
      const updatedProfiles = orgProfiles.includes(profileName)
        ? orgProfiles.filter((name) => name !== profileName)
        : [...orgProfiles, profileName];

      // Save the profiles immediately after selection change
      dispatch(updateOrganizationProfiles(organizationId, updatedProfiles));

      return {
        ...prevSelectedProfiles,
        [organizationId]: updatedProfiles,
      };
    });
  };

  const handleSwitchChange = (organizationId, switchType) => {
    setSwitchStates((prevState) => ({
      ...prevState,
      [organizationId]: {
        ...prevState[organizationId],
        [switchType]: !prevState[organizationId]?.[switchType],
      },
    }));
  };

  const handleCreateOrganization = (e) => {
    e.preventDefault();
    const slug = orgName.toLowerCase().split(" ").join("-");
    const orgData = {
      name: orgName,
      slug: slug,
    };
    dispatch(createOrganization(orgData)).then(() =>
      fetchOrganizationsWithDateRange()
    );
  };

  return (
    <div className='dashboard-input-container'>
      <div className='dashboard-header mb-3 d-flex'>
        <h1>Backend Dashboard</h1>
        <DatePicker
          selectsRange={true}
          startDate={startDate}
          endDate={endDate}
          onChange={(update) => {
            setDateRange(update);
          }}
          isClearable={true}
          placeholderText='Date range'
          className='form-control me-2'
        />
      </div>

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Organization Name</th>
            <th>Users</th>
            <th>Subscription</th>
            <th>Video</th>
            <th>Podcast</th>
            <th>Profiles</th>
            <th>Articles</th>
            <th>Completion Tokens</th>
            <th>Prompt Tokens</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {organizations.map((org) => (
            <tr key={org.organizationId}>
              <td
                onClick={() =>
                  navigate(
                    `/dashboard/admin/organization/${org.organizationId}`
                  )
                }
                style={{ cursor: "pointer" }}
              >
                {org.organizationName}
              </td>{" "}
              <td className='text-center'>{org.userCount}</td>
              <td>Subscription</td>
              <td className='text-center'>
                <Form.Check
                  type='switch'
                  id={`video-switch-${org.organizationId}`}
                  label=''
                  checked={
                    switchStates[org.organizationId]?.videoAccess || false
                  }
                  onChange={() =>
                    handleSwitchChange(org.organizationId, "videoAccess")
                  }
                />
              </td>
              <td className='text-center mx-auto'>
                <Form.Check
                  type='switch'
                  id={`podcast-switch-${org.organizationId}`}
                  label=''
                  className='mx-auto'
                  checked={
                    switchStates[org.organizationId]?.podcastAccess || false
                  }
                  onChange={() =>
                    handleSwitchChange(org.organizationId, "podcastAccess")
                  }
                />
              </td>
              <td>
                <Dropdown>
                  <Dropdown.Toggle
                    variant='secondary'
                    id={`dropdown-${org.organizationId}`}
                  >
                    Select Profiles
                  </Dropdown.Toggle>
                  <Dropdown.Menu as={CustomMenu}>
                    {profiles.map((profile) => (
                      <Dropdown.Item as='div' key={profile}>
                        <Form.Check
                          type='checkbox'
                          id={`profile-${profile}`}
                          label={profile}
                          checked={
                            selectedProfiles[org.organizationId]?.includes(
                              profile
                            ) || false
                          }
                          onChange={() =>
                            handleProfileSelect(org.organizationId, profile)
                          }
                        />
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </td>
              <td className='text-center'>
                {org.articles !== undefined ? org.articles : "N/A"}
              </td>
              <td className='text-center'>
                {org.completionTokens !== undefined
                  ? org.completionTokens
                  : "N/A"}
              </td>
              <td className='text-center'>
                {org.promptTokens !== undefined ? org.promptTokens : "N/A"}
              </td>
              <td className='text-center'>
                <Button
                  variant='danger'
                  onClick={() => handleDeleteOrganization(org.organizationId)}
                >
                  Delete
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Form onSubmit={handleCreateOrganization}>
        <h2 className='mb-3'>Add Organizations</h2>
        <InputGroup className='mb-3'>
          <Form.Control
            type='text'
            placeholder='Name'
            value={orgName}
            onChange={(e) => setOrgName(e.target.value)}
          />
          <Button variant='primary' type='submit'>
            Add
          </Button>
        </InputGroup>
      </Form>

      <Form onSubmit={handleInviteSubmit}>
        <h2 className='mb-3'>Invite Users to Organizations</h2>
        <Form.Group controlId='organizationSelect'>
          <Form.Control
            as='select'
            value={selectedOrgId}
            onChange={(e) => setSelectedOrgId(e.target.value)}
            required
            className='mb-3'
          >
            <option value=''>Select Organization</option>
            {organizations.map((org) => (
              <option key={org.organizationId} value={org.organizationId}>
                {org.organizationName}
              </option>
            ))}
          </Form.Control>
        </Form.Group>

        <InputGroup className='mb-3'>
          <Form.Control
            placeholder='Email address'
            aria-label='Email address'
            type='email'
            value={inviteEmail}
            onChange={(e) => setInviteEmail(e.target.value)}
            required
          />
          <Button type='submit' variant='primary'>
            Invite User
          </Button>
        </InputGroup>
      </Form>
    </div>
  );
};

// Custom Menu to prevent closing dropdown on click
const CustomMenu = React.forwardRef(
  ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        <ul className='list-unstyled'>{React.Children.toArray(children)}</ul>
      </div>
    );
  }
);

export default SuperAdmin;

import {
  GET_ORGANIZATION_FAIL,
  GET_ORGANIZATION_SUCCESS,
  CREATE_ORGANIZATION_FAIL,
  CREATE_ORGANIZATION_SUCCESS,
  GET_ORGANIZATION_USERS_FAIL,
  GET_ORGANIZATION_USERS_SUCCESS,
  ADD_USER_TO_ORGANIZATION_FAIL,
  ADD_USER_TO_ORGANIZATION_SUCCESS,
  ADD_USER_TO_ORGANIZATION_SUPER_FAIL,
  ADD_USER_TO_ORGANIZATION_SUPER_SUCCESS,
  DELETE_ORGANIZATION_USER_FAIL,
  DELETE_ORGANIZATION_USER_SUCCESS,
  CLEAR_MESSAGES,
  FETCH_ORGANIZATIONS_SUCCESS,
  FETCH_ORGANIZATIONS_FAIL,
  FETCH_ORGANIZATION_SUCCESS,
  FETCH_ORGANIZATION_FAIL,
  DELETE_ORGANIZATION_SUCCESS,
  DELETE_ORGANIZATION_FAIL,
  FETCH_PROFILES_SUCCESS,
  FETCH_PROFILES_FAIL,
  FETCH_SAVE_PROFILES_SUCCESS,
  FETCH_SAVE_PROFILES_FAIL,
  UPDATE_ORGANIZATION_PROFILES_SUCCESS,
  UPDATE_ORGANIZATION_PROFILES_FAIL,
  FETCH_ORGANIZATION_PROFILES_SUCCESS,
  FETCH_ORGANIZATION_PROFILES_FAIL,
  FETCH_ORGANIZATION_DATA_REQUEST,
  FETCH_ORGANIZATION_DATA_SUCCESS,
  FETCH_ORGANIZATION_DATA_FAILURE,
} from "../actions/types";

const initialState = {
  organizationProfiles: [],
  organizations: [],
  users: [],
  profiles: [],
  error: null,
  organizationData: [],
  loading: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case FETCH_ORGANIZATION_PROFILES_SUCCESS:
      return {
        ...state,
        organizationProfiles: payload,
      };
    case DELETE_ORGANIZATION_SUCCESS:
      return {
        ...state,
        error: null,
        organizations: state.organizations.filter((org) => org.id !== payload),
      };
    case DELETE_ORGANIZATION_FAIL:
      return {
        ...state,
        error: payload,
      };
    case FETCH_ORGANIZATIONS_SUCCESS:
      return {
        ...state,
        organizations: payload,
      };
    case FETCH_ORGANIZATIONS_FAIL:
      return {
        ...state,
        organizations: [],
        error: payload,
      };
    case FETCH_ORGANIZATION_SUCCESS:
      return {
        ...state,
        organizations: payload,
      };
    case FETCH_ORGANIZATION_FAIL:
      return {
        ...state,
        organizations: [],
        error: payload,
      };
    case GET_ORGANIZATION_USERS_SUCCESS:
      return {
        ...state,
        users: payload,
      };
    case GET_ORGANIZATION_SUCCESS:
      return {
        ...state,
        organizations: state.organizations.map((org) =>
          org.id === payload.id ? payload : org
        ),
      };
    case CREATE_ORGANIZATION_SUCCESS:
      return {
        ...state,
        organizations: [...state.organizations, payload],
      };
    case CREATE_ORGANIZATION_FAIL:
    case GET_ORGANIZATION_FAIL:
      return {
        ...state,
        error: payload,
      };
    case ADD_USER_TO_ORGANIZATION_SUCCESS:
    case ADD_USER_TO_ORGANIZATION_SUPER_SUCCESS:
      return {
        ...state,
        users: [...state.users, payload],
      };
    case ADD_USER_TO_ORGANIZATION_FAIL:
    case ADD_USER_TO_ORGANIZATION_SUPER_FAIL:
    case FETCH_ORGANIZATION_PROFILES_FAIL:
      return {
        ...state,
        error: payload,
      };
    case GET_ORGANIZATION_USERS_FAIL:
      return {
        ...state,
        users: [],
      };
    case DELETE_ORGANIZATION_USER_SUCCESS:
      return {
        ...state,
        users: state.users.filter((user) => user.id !== payload),
      };
    case CLEAR_MESSAGES:
      return {
        ...state,
        success: null,
        error: null,
        message: null,
      };
    case DELETE_ORGANIZATION_USER_FAIL:
      return {
        ...state,
        error: payload,
      };
    case FETCH_PROFILES_SUCCESS:
      return {
        ...state,
        profiles: payload,
        error: null,
      };
    case FETCH_PROFILES_FAIL:
      return {
        ...state,
        profiles: [],
        error: payload,
      };
    case FETCH_SAVE_PROFILES_SUCCESS:
      return {
        ...state,
        profiles: payload,
        error: null,
      };
    case FETCH_SAVE_PROFILES_FAIL:
      return {
        ...state,
        profiles: [],
        error: payload,
      };
    case UPDATE_ORGANIZATION_PROFILES_FAIL:
      return {
        ...state,
        error: payload,
      };
    case UPDATE_ORGANIZATION_PROFILES_SUCCESS:
      return {
        ...state,
        error: null,
      };
    case FETCH_ORGANIZATION_DATA_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_ORGANIZATION_DATA_SUCCESS:
      return {
        ...state,
        organizationData: payload,
        loading: false,
        error: null,
      };
    case FETCH_ORGANIZATION_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
}

import React, { useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  moveNextStep,
  movePreviousStep,
  getMoreInfo,
  saveSelectedSources,
  generateSummary,
  saveSummaries,
  updateTotalCompletionTokens,
  updateTotalPromptTokens,
} from "../../actions/article";
import LoadingIndicator from "./LoadingIndicator";
import { Button } from "react-bootstrap";

const SearchResults = ({ user }) => {
  const dispatch = useDispatch();
  const sourceRefs = useRef({});
  const formData = useSelector((state) => state.article.formData);
  const {
    prompt,
    all_sources,
    summaries,
    totalPromptTokens,
    totalCompletionTokens,
  } = formData; // Assuming you have summaries in your state
  const selected_sources = useSelector(
    (state) => state.article.formData.selected_sources
  );
  const orgId = user ? user.orgId : null;
  const language = user ? user.language : null;
  const current_profile = user ? user.current_profile : null;
  let updatedTotalPromptTokens = totalPromptTokens;
  let updatedTotalCompletionTokens = totalCompletionTokens;

  const [loading, setLoading] = useState(false); // State to track loading state

  // Function to handle checkbox change
  const handleCheckboxChange = (index) => {
    const isChecked = sourceRefs.current[index].checked;
    const source = all_sources.result[index];
    const updatedSources = isChecked
      ? [...selected_sources, source] // Add source to selected sources
      : selected_sources.filter((s) => s !== source); // Remove source from selected sources
    dispatch(saveSelectedSources(updatedSources)); // Dispatch action to save selected sources
  };

  // Function to generate summary for a single source
  const generateSummaryForSource = async (source) => {
    try {
      // Dispatch the generateSummary action
      const response = await dispatch(
        generateSummary(prompt, source.url, orgId, current_profile, language)
      );

      // Update local variables
      updatedTotalCompletionTokens += response.metadata.completionTokens;
      updatedTotalPromptTokens += response.metadata.promptTokens;

      // Dispatch actions to update Redux state
      dispatch(updateTotalPromptTokens(updatedTotalPromptTokens));
      dispatch(updateTotalCompletionTokens(updatedTotalCompletionTokens));
      // Extract the summary from the response
      const summary = response.result;
      // Return the summary
      return summary;
    } catch (error) {
      console.error("Error generating summary for source:", source, error);
      throw error;
    }
  };

  const handleNextStep = async () => {
    setLoading(true); // Set loading state to true before API call
    try {
      if (selected_sources.length === 0) {
        // If no sources are selected, proceed without generating summaries
        const getMoreInfoResponse = await dispatch(
          getMoreInfo(prompt, summaries, orgId, current_profile, language)
        );
        // Update local variables
        updatedTotalCompletionTokens +=
          getMoreInfoResponse.metadata.completionTokens;
        updatedTotalPromptTokens += getMoreInfoResponse.metadata.promptTokens;

        // Dispatch actions to update Redux state
        dispatch(updateTotalPromptTokens(updatedTotalPromptTokens));
        dispatch(updateTotalCompletionTokens(updatedTotalCompletionTokens));
      } else {
        // Generate summaries for each selected source
        const summaryPromises = selected_sources.map(generateSummaryForSource);
        const generatedSummaries = await Promise.all(summaryPromises);
        // Update the summaries array in the state
        const updatedSummaries = [...summaries, ...generatedSummaries];
        dispatch(saveSummaries(updatedSummaries)); // Dispatch action to update summaries array
        // Proceed with other actions
        const getMoreInfoResponse = await dispatch(
          getMoreInfo(prompt, summaries, orgId, current_profile, language)
        );
        // Update local variables
        updatedTotalCompletionTokens +=
          getMoreInfoResponse.metadata.completionTokens;
        updatedTotalPromptTokens += getMoreInfoResponse.metadata.promptTokens;

        // Dispatch actions to update Redux state
        dispatch(updateTotalPromptTokens(updatedTotalPromptTokens));
        dispatch(updateTotalCompletionTokens(updatedTotalCompletionTokens));
      }
      await dispatch(moveNextStep());
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false); // Set loading state to false after API call completes
    }
  };

  return (
    <div className='step-content dashboard-input-wrapper step-3'>
      {loading ? ( // Display loader when loading state is true
        <div className='dotloader-wrapper'>
          <LoadingIndicator text='Reviewing your input' />
        </div>
      ) : (
        <>
          <h2>Select additional article sources</h2>
          {all_sources ? (
            all_sources.result.map((source, index) => (
              <div key={index} className='source-card'>
                <input
                  type='checkbox'
                  ref={(ref) => (sourceRefs.current[index] = ref)}
                  onChange={() => handleCheckboxChange(index)} // Handle checkbox change
                />
                <div className='card-body'>
                  <h4>{source.title}</h4>
                  <p>{source.snippet}</p>
                  <h6>{source.date}</h6>
                  <a
                    href={source.url}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    {source.url}
                  </a>
                </div>
              </div>
            ))
          ) : (
            <div className='dotloader-wrapper'>
              <LoadingIndicator text='Reviewing your inputs' />
            </div>
          )}

          <div className='step-nav'>
            <Button variant='primary' onClick={movePreviousStep}>
              Back
            </Button>
            <Button variant='primary' onClick={handleNextStep}>
              Next Step
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default SearchResults;

import React from "react";
import { Link, NavLink } from "react-router-dom";
import logo from "../assets/images/logo.svg";
import { Navbar, Nav, Container, Button } from "react-bootstrap";

const CustomNavbar = ({ isAuthenticated, logout }) => {
  return (
    <Navbar bg="light" expand="lg">
      <Container>
        <Navbar.Brand as={Link} to="/">
          <img src={logo} alt="Laio Logo" height="50" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarNavAltMarkup" />
        <Navbar.Collapse id="navbarNavAltMarkup">
          <Nav className="me-auto">
            <Nav.Link as={NavLink} to="/#benefits">
              Benefits
            </Nav.Link>
            <Nav.Link as={NavLink} to="/#how-it-works">
              How It Works
            </Nav.Link>
            <Nav.Link as={NavLink} to="/#faq">
              FAQ
            </Nav.Link>
            {/* <Nav.Link as={NavLink} to="/home#pricing">
              Pricing
            </Nav.Link> */}
          </Nav>
          <Nav>
            {isAuthenticated ? (
              <Button variant="outline-danger" onClick={logout}>
                Logout
              </Button>
            ) : (
              <Button variant="primary" as={NavLink} to="/login">
                Login
              </Button>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default CustomNavbar;

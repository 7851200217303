import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { deleteArticle, getArticles } from "../actions/article";
import { Navigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

const MyStories = ({ user }) => {
  const dispatch = useDispatch();
  const articles = useSelector((state) => state.article.articles);
  // const user = useSelector((state) => state.auth.user);
  // const { id } = user;
  const id = user ? user.id : null;

  useEffect(() => {
    dispatch(getArticles(id));
  }, [dispatch, id]);

  const [selectedArticle, setSelectedArticle] = useState(null);

  const handleArticleClick = (articleId) => {
    setSelectedArticle(articleId);
  };

  const handleDeleteArticle = (articleId) => {
    if (window.confirm("Are you sure you want to delete this article?")) {
      dispatch(deleteArticle(articleId)).then(() => getArticles(id));
    }
  };

  if (selectedArticle) {
    return <Navigate to={`/dashboard/article/${selectedArticle}`} />;
  }

  return (
    <div className='dashboard-input-wrapper my-stories-wrapper'>
      <h3 className=''>Your Articles</h3>
      {Array.isArray(articles) &&
        articles.map((article) => (
          <ArticleCard
            key={article.id}
            article={article}
            onClick={() => handleArticleClick(article.id)}
            onDelete={() => handleDeleteArticle(article.id)}
          />
        ))}
    </div>
  );
};

const ArticleCard = ({ article, onClick, onDelete }) => {
  return (
    <div className='card'>
      <h5 className='card-title' onClick={() => onClick(article.id)}>
        {article.title}
      </h5>
      <button className='delete-button' onClick={() => onDelete(article.id)}>
        <FontAwesomeIcon icon={faTrash} />
      </button>
    </div>
  );
};

export default MyStories;

import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  LOAD_USER_SUCCESS,
  LOAD_USER_FAIL,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,
  FORGOT_PASSWORD_EMAIL_SENT,
  FORGOT_PASSWORD_EMAIL_FAIL,
} from "../actions/types";

const token = localStorage.getItem("token");

const initialState = {
  token: token ? token : null,
  isAuthenticated: token ? true : false,
  user: null,
  tokenExpiration: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case LOGIN_SUCCESS:
      // localStorage.setItem("token", payload);
      return {
        ...state,
        token: payload,
        isAuthenticated: true,
      };
    case FORGOT_PASSWORD_EMAIL_SENT:
      return {
        ...state,
        message: action.payload,
      };
    case LOGIN_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case LOGOUT:
      localStorage.removeItem("token");
      return {
        ...state,
        token: null,
        isAuthenticated: false,
      };
    case LOAD_USER_SUCCESS:
      return {
        ...state,
        user: payload,
        tokenExpiration: action.tokenExpiration,
      };
    case LOAD_USER_FAIL:
      return {
        ...state,
        user: null,
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        message: action.payload,
      };
    case FORGOT_PASSWORD_EMAIL_FAIL:
    case RESET_PASSWORD_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}

import axios from "axios";
import {
  CREATE_ORGANIZATION_SUCCESS,
  CREATE_ORGANIZATION_FAIL,
  FETCH_ORGANIZATIONS_SUCCESS,
  FETCH_ORGANIZATIONS_FAIL,
  FETCH_ORGANIZATION_SUCCESS,
  FETCH_ORGANIZATION_FAIL,
  DELETE_ORGANIZATION_SUCCESS,
  DELETE_ORGANIZATION_FAIL,
  CLEAR_MESSAGES,
  FETCH_PROFILES_SUCCESS,
  FETCH_PROFILES_FAIL,
  FETCH_SAVE_PROFILES_SUCCESS,
  FETCH_SAVE_PROFILES_FAIL,
  UPDATE_ORGANIZATION_PROFILES_SUCCESS,
  UPDATE_ORGANIZATION_PROFILES_FAIL,
  FETCH_ORGANIZATION_PROFILES_SUCCESS,
  FETCH_ORGANIZATION_PROFILES_FAIL,
  FETCH_ORGANIZATION_DATA_REQUEST,
  FETCH_ORGANIZATION_DATA_FAILURE,
  FETCH_ORGANIZATION_DATA_SUCCESS,
} from "./types";

export const createOrganization = (organizationData) => async (dispatch) => {
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}api/organizations`,
      organizationData,
      config
    );
    dispatch({
      type: CREATE_ORGANIZATION_SUCCESS,
      payload: res.data,
    });
    return res.data; // Ensure the promise resolves with the response data
  } catch (err) {
    dispatch({
      type: CREATE_ORGANIZATION_FAIL,
    });
    throw err; // Ensure the promise rejects with the error
  }
};

// export const fetchOrganizations = () => async (dispatch) => {
//   try {
//     const res = await axios.get(
//       `${process.env.REACT_APP_API_URL}api/organizations`
//     );
//     dispatch({
//       type: FETCH_ORGANIZATIONS_SUCCESS,
//       payload: res.data,
//     });
//   } catch (err) {
//     console.error("Fetch organizations error:", err);
//     dispatch({
//       type: FETCH_ORGANIZATIONS_FAIL,
//       payload: err.response.data.message || "Failed to fetch organizations",
//     });
//   }
// };

export const fetchOrganization = (id) => async (dispatch) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}api/organizations/${id}`
    );
    dispatch({
      type: FETCH_ORGANIZATION_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    console.error("Fetch organization error:", err);
    dispatch({
      type: FETCH_ORGANIZATION_FAIL,
      payload: err.response.data.message || "Failed to fetch organization",
    });
  }
};

export const deleteOrganization = (id) => async (dispatch) => {
  try {
    await axios.delete(
      `${process.env.REACT_APP_API_URL}api/organizations/${id}`
    );

    dispatch({
      type: DELETE_ORGANIZATION_SUCCESS,
      payload: id,
    });
  } catch (err) {
    dispatch({
      type: DELETE_ORGANIZATION_FAIL,
      payload: err.response.data.message || "Failed to delete organization",
    });
  }
};

export const OrgsClearMessages = () => ({
  type: CLEAR_MESSAGES,
});

export const fetchProfiles = () => async (dispatch) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}api/profiles`
    );
    dispatch({
      type: FETCH_PROFILES_SUCCESS,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: FETCH_PROFILES_FAIL,
      payload: err.message,
    });
  }
};

export const updateOrganizationProfiles =
  (organizationId, profileNames) => async (dispatch) => {
    try {
      await axios.put(
        `${process.env.REACT_APP_API_URL}api/organizations/${organizationId}`,
        {
          organizationId,
          profileNames,
        }
      );
      dispatch({
        type: UPDATE_ORGANIZATION_PROFILES_SUCCESS,
      });
    } catch (err) {
      console.error("Update organization profiles error:", err);
      dispatch({
        type: UPDATE_ORGANIZATION_PROFILES_FAIL,
        payload:
          err.response.data.message || "Failed to update organization profiles",
      });
    }
  };

export const fetchOrganizationProfiles =
  (organizationId) => async (dispatch) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}api/organizations/profiles/${organizationId}`
      );
      dispatch({
        type: FETCH_ORGANIZATION_PROFILES_SUCCESS,
        payload: response.data,
      });
    } catch (err) {
      console.error("Fetch organization profiles error:", err);
      dispatch({
        type: FETCH_ORGANIZATION_PROFILES_FAIL,
        payload:
          err.response.data.message || "Failed to fetch organization profiles",
      });
    }
  };

export const fetchOrganizationDataRequest = () => ({
  type: FETCH_ORGANIZATION_DATA_REQUEST,
});

export const fetchOrganizationDataSuccess = (data) => ({
  type: FETCH_ORGANIZATION_DATA_SUCCESS,
  payload: data,
});

export const fetchOrganizationDataFailure = (error) => ({
  type: FETCH_ORGANIZATION_DATA_FAILURE,
  payload: error,
});

export const fetchOrganizations = (startDate, endDate) => {
  return async (dispatch) => {
    dispatch(fetchOrganizationDataRequest());
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}api/organizations/data?startDate=${startDate}&endDate=${endDate}`
      );
      dispatch(fetchOrganizationDataSuccess(response.data));
      return response.data; // Ensure data is returned
    } catch (error) {
      dispatch(fetchOrganizationDataFailure(error.message));
      throw error;
    }
  };
};

import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FaSearch, FaPencilAlt, FaVideo, FaShieldAlt } from "react-icons/fa";

const BenefitsSection = () => (
  <section id="benefits" className="benefits-section py-5">
    <Container>
      <h2 className="text-center mb-5">Benefits of Using Laio</h2>
      <Row className="g-4">
        <Col md={3}>
          <div className="card h-100 border-0 shadow-sm">
            <div className="card-body text-center">
              <FaSearch className="benefit-icon mb-3" />
              <h3 className="h5 card-title">AI-Assisted Research</h3>
              <p className="card-text">
                Discover credible sources and new insights.
              </p>
            </div>
          </div>
        </Col>
        <Col md={3}>
          <div className="card h-100 border-0 shadow-sm">
            <div className="card-body text-center">
              <FaPencilAlt className="benefit-icon mb-3" />
              <h3 className="h5 card-title">Contextual Content Creation</h3>
              <p className="card-text">
                Generate content that fits your narrative.
              </p>
            </div>
          </div>
        </Col>
        <Col md={3}>
          <div className="card h-100 border-0 shadow-sm">
            <div className="card-body text-center">
              <FaVideo className="benefit-icon mb-3" />
              <h3 className="h5 card-title">
                Seamless Video & Podcast Production (Coming Soon)
              </h3>
              <p className="card-text">
                Create multimedia content effortlessly.
              </p>
            </div>
          </div>
        </Col>
        <Col md={3}>
          <div className="card h-100 border-0 shadow-sm">
            <div className="card-body text-center">
              <FaShieldAlt className="benefit-icon mb-3" />
              <h3 className="h5 card-title">GDPR Compliance & Ethical AI</h3>
              <p className="card-text">
                Your privacy and ethics are our priority.
              </p>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  </section>
);

export default BenefitsSection;

import React, { useState, useEffect } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import {
  updateProfile,
  listLanguages,
  userClearMessages,
} from "../actions/user";
import { fetchOrganizationProfiles } from "../actions/organizations";
import { authClearMessages, loadUser } from "../actions/auth";
import ChangePassword from "./ChangePassword";
import { ToastContainer, toast } from "react-toastify";
import { deleteUser } from "../actions/user";
import { logout } from "../actions/auth";

const Settings = ({
  deleteUser,
  updateProfile,
  user_id,
  first_name,
  last_name,
  email,
  language,
  organizationId,
  current_profile,
}) => {
  const [formData, setFormData] = useState({
    first_name: first_name || "",
    last_name: last_name || "",
    email: email || "",
    language: language || "",
    current_profile: current_profile || "",
  });

  const message = useSelector((state) => state.user.message);
  const error = useSelector((state) => state.user.error);
  const dispatch = useDispatch();
  const [selectedOrgProfile, setSelectedOrgProfile] = useState(null);
  const organizationProfiles = useSelector(
    (state) => state.organizations.organizationProfiles.profileNames
  );
  const languages = useSelector((state) => state.user.languages);

  useEffect(() => {
    dispatch(listLanguages());
  }, [dispatch]);

  useEffect(() => {
    setFormData({
      first_name: first_name || "",
      last_name: last_name || "",
      email: email || "",
      language: language || "",
      current_profile: current_profile || "", // Add this line
    });
  }, [first_name, last_name, email, language, current_profile, dispatch]);

  useEffect(() => {
    if (organizationId) {
      dispatch(fetchOrganizationProfiles(organizationId));
    }
  }, [dispatch, organizationId]);

  useEffect(() => {
    if (message) {
      toast.success(message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else if (error) {
      toast.error(error, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }, [message, error, dispatch]);

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();
    const { first_name, last_name, email, language, current_profile } =
      formData;
    dispatch(userClearMessages());
    updateProfile(
      user_id,
      first_name,
      last_name,
      email,
      language,
      current_profile
    ).then(() => {
      // Fetch the latest user data after updating the profile
      dispatch(loadUser());
    });
  };

  const handleDeleteAccount = async () => {
    if (window.confirm("Are you sure you want to delete your account?")) {
      try {
        // Call deleteUser action
        await deleteUser(user_id);

        // Call logout action after deleteUser action is completed
        dispatch(logout());

        // You can also redirect the user to a different page after logging out
        // history.push('/login'); // Assuming you are using React Router and have access to history object
      } catch (error) {
        // Handle errors if any
        console.error("Error deleting user:", error);
      }
    }
  };

  return (
    <div className='dashboard-input-wrapper'>
      <ToastContainer />
      <div className='dashboard-input-container'>
        {first_name ? (
          <h1 className='mt-3'>Welcome {first_name}!</h1>
        ) : (
          <h1 className='mt-3'>Welcome!</h1>
        )}
        <p className='mt-3 mb-3'>Update your profile</p>
      </div>
      <div className='dashboard-input-container'>
        <form onSubmit={(e) => onSubmit(e)}>
          <div className='form-group__wrapper'>
            <label htmlFor='first_name' className='form-label'>
              First Name
            </label>
            <input
              type='text'
              className='form-control'
              name='first_name'
              onChange={(e) => onChange(e)}
              value={formData.first_name}
            />
          </div>
          <div className='form-group__wrapper mt-4'>
            <label htmlFor='last_name' className='form-label'>
              Last Name
            </label>
            <input
              type='text'
              className='form-control'
              name='last_name'
              onChange={(e) => onChange(e)}
              value={formData.last_name}
            />
          </div>
          <div className='form-group__wrapper mt-4'>
            <label htmlFor='email' className='form-label'>
              Email
            </label>
            <input
              type='email'
              className='form-control'
              name='email'
              onChange={(e) => onChange(e)}
              value={formData.email}
            />
          </div>
          <div className='form-group__wrapper mt-4'>
            <label htmlFor='language' className='form-label'>
              Default Language
            </label>
            <select
              id='language'
              className='form-control'
              name='language'
              value={formData.language}
              onChange={(e) => onChange(e)}
            >
              {languages &&
                languages.length > 0 &&
                languages.map((language) => (
                  <option key={language.code} value={language.code}>
                    {language.languageName}
                  </option>
                ))}
            </select>
          </div>

          <div className='form-group__wrapper mt-4'>
            <label htmlFor='profile' className='form-label'>
              Select Profile
            </label>
            <select
              id='profile'
              className='form-control'
              name='current_profile'
              value={formData.current_profile}
              onChange={(e) => onChange(e)}
            >
              <option value=''>Select Profile</option>
              {organizationProfiles &&
                organizationProfiles.length > 0 &&
                organizationProfiles.map((profile) => (
                  <option key={profile.id} value={profile}>
                    {profile}
                  </option>
                ))}
            </select>
          </div>
          <div className='form-group__wrapper'>
            <button className='btn btn-primary mt-4'>Update Profile</button>
          </div>
        </form>
      </div>
      <div className='dashboard-input-container'>
        <ChangePassword />
      </div>
      <div className='dashboard-input-container'>
        <div className='form-group__wrapper'>
          <button
            type='button'
            href='#!'
            className='btn btn-danger'
            onClick={() => handleDeleteAccount()}
          >
            Delete Account
          </button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user_id: state.auth.user ? state.auth.user.id : null,
  first_name: state.auth.user ? state.auth.user.first_name : null,
  last_name: state.auth.user ? state.auth.user.last_name : null,
  email: state.auth.user ? state.auth.user.email : null,
  language: state.auth.user ? state.auth.user.language : null,
  organizationId: state.auth.user ? state.auth.user.organizationId : null,
  current_profile: state.auth.user ? state.auth.user.current_profile : null,
});

export default connect(mapStateToProps, {
  deleteUser,
  updateProfile,
})(Settings);

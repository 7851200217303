// Sidebar.js
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPencil,
  faNewspaper,
  faGear,
  faToolbox,
  faRightFromBracket,
  faAnglesLeft,
  faAnglesRight,
  faSquareRootVariable,
} from "@fortawesome/free-solid-svg-icons";
import logo from "../assets/images/logo.svg";
import { logout } from "../actions/auth";
import { connect } from "react-redux";
import { resetForm } from "../actions/article";

const Sidebar = ({ logout, isSuperuser, isAdmin, orgId }) => {
  const [collapsed, setCollapsed] = useState(false);
  const dispatch = useDispatch();

  const handleResetForm = () => {
    dispatch(resetForm()); // Dispatch the resetForm action
  };

  return (
    <div className={`sidebar ${collapsed ? "collapsed" : ""}`}>
      <button
        className='toggle-button'
        onClick={() => setCollapsed(!collapsed)}
      >
        {collapsed ? (
          <FontAwesomeIcon icon={faAnglesRight} />
        ) : (
          <FontAwesomeIcon icon={faAnglesLeft} />
        )}
      </button>
      {/* Logo */}
      <div class='brand'>
        <NavLink to='/'>
          <img class='logo' src={logo} alt='Logo' />
        </NavLink>
      </div>
      <div className='sidebar__container'>
        <div className='sidebar-functions__story'>
          <ul>
            <li>
              <NavLink to='/dashboard/new-story' onClick={handleResetForm}>
                <FontAwesomeIcon icon={faPencil} size='xl' />
                {collapsed ? "" : "New Story"}
              </NavLink>
            </li>
            <li>
              <NavLink to='/dashboard/my-stories'>
                <FontAwesomeIcon icon={faNewspaper} size='xl' />
                {collapsed ? "" : "My Stories"}
              </NavLink>
            </li>
          </ul>
        </div>
        <div className='sidebar-functions__settings'>
          <ul>
            <li>
              <NavLink to='/dashboard/settings'>
                <FontAwesomeIcon icon={faGear} size='xl' />
                {collapsed ? "" : "Settings"}
              </NavLink>
            </li>
            {/* {isSuperuser && (
              <li>
                <NavLink to='/dashboard/admin'>
                  <FontAwesomeIcon icon={faSquareRootVariable} size='xl' />
                  {collapsed ? "" : "Super Admin"}
                </NavLink>
              </li>
            )} */}
            {isSuperuser && (
              <li>
                <NavLink to='/dashboard/superadmin'>
                  <FontAwesomeIcon icon={faSquareRootVariable} size='xl' />
                  {collapsed ? "" : "Super Admin"}
                </NavLink>
              </li>
            )}
            {isAdmin && (
              <li>
                <NavLink to={`/dashboard/admin/organization/${orgId}`}>
                  <FontAwesomeIcon icon={faToolbox} size='xl' />
                  {collapsed ? "" : "Admin"}
                </NavLink>
              </li>
            )}
            <li>
              <button className='logout-button' onClick={() => logout()}>
                <FontAwesomeIcon icon={faRightFromBracket} size='xl' />
                {collapsed ? "" : "Log Out"}
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isSuperuser: state.auth.user ? state.auth.user.is_superuser : null,
  isAdmin: state.auth.user ? state.auth.user.is_admin : null,
  orgId: state.auth.user ? state.auth.user.organizationId : null,
});

export default connect(mapStateToProps, { logout })(Sidebar);

import React from "react";
import { Container, Button } from "react-bootstrap";

const HeroSection = () => (
  <section className="hero-section bg-light text-center py-5">
    <Container>
      <h1>Supercharge Your Journalism with AI</h1>
      <p>
        Laio helps you create high-quality content faster, while you stay in
        control.
      </p>
      <Button
        href="mailto:info@laio.pro?subject=Request%20for%20Laio%20Account&body=Hello%20Laio%20team%2C%0A%0AI%20would%20like%20to%20request%20an%20account%20to%20try%20Laio.%20Please%20provide%20me%20with%20more%20information%20about%20getting%20started.%0A%0AThank%20you%2C%0A%5BYour%20Name%5D"
        variant="primary"
        size="lg"
        className="btn-hover-effect"
      >
        Get Started
      </Button>
    </Container>
  </section>
);

export default HeroSection;

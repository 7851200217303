import {
  SET_PROMPT,
  SUBMIT_INPUT_FAIL,
  EXPAND_INPUT_SUCCESS,
  EXPAND_INPUT_FAIL,
  GENERATE_SUMMARY_SUCCESS,
  GET_INPUT_WITHOUT_URLS_FAIL,
  CREATE_SEARCH_TERMS_FAIL,
  MOVE_NEXT_STEP,
  MOVE_PREVIOUS_STEP,
  UPDATE_SEARCH_TERMS,
  POST_SEARCH_TERMS_SUCCESS,
  POST_SEARCH_TERMS_FAIL,
  GET_SEARCH_WORDS_SUCCESS,
  GET_SEARCH_RESULTS_SUCCESS,
  GET_SEARCH_RESULTS_FAIL,
  GET_COARSE_FILTER_SUCCESS,
  GET_COARSE_FILTER_FAIL,
  SAVE_SELECTED_SOURCES,
  SAVE_MORE_INFO_ANSWERS,
  GET_MORE_INFO_SUCCESS,
  WRITE_TITLE_INTRO_SUCCESS,
  WRITE_ARTICLE_SUCCESS,
  POST_COARSE_FILTER_FAIL,
  POST_COARSE_FILTER_SUCCESS,
  GET_RESEARCH_REVIEW_FAIL,
  GET_OUTLINE_FAIL,
  GET_OUTLINE_SUCCESS,
  GET_TITLE_FAIL,
  GET_TITLE_SUCCESS,
  POST_OUTLINE_FAIL,
  POST_OUTLINE_SUCCESS,
  POST_TITLE_FAIL,
  POST_TITLE_SUCCESS,
  GET_ARTICLE_BODY_FAIL,
  GET_ARTICLE_BODY_SUCCESS,
  GET_ARTICLE_HTML_FAIL,
  GET_ARTICLE_HTML_SUCCESS,
  GET_ARTICLES_SUCCESS,
  SAVE_SUMMARIES,
  UPDATE_INTRO,
  UPDATE_TITLE,
  RESET_FORM,
  SAVE_ARTICLE_SUCCESS,
  SAVE_ARTICLE_FAIL,
  UPDATE_TOTAL_PROMPT_TOKENS,
  UPDATE_TOTAL_COMPLETION_TOKENS,
  FETCH_ARTICLES_FAIL,
  FETCH_ARTICLES_SUCCESS,
  FETCH_ARTICLE_FAIL,
  FETCH_ARTICLE_SUCCESS,
  DELETE_ARTICLE_FAIL,
  DELETE_ARTICLE_SUCCESS,
} from "../actions/types";

const initialState = {
  articles: [],
  article: [],
  currentStep: 1,
  formData: {
    prompt: "What should Laio write about today?",
    summaries: [],
    search_terms: "",
    all_sources: [],
    selected_sources: [],
    more_info_questions: "",
    more_info_answers: "",
    title: "",
    intro: "",
    body: "",
    html: "",
    promptTokens: "",
    completionTokens: "",
    totalPromptTokens: "",
    totalCompletionTokens: "",
  },
};

export default function (state = initialState, action) {
  const { payload } = action;

  switch (action.type) {
    case RESET_FORM:
      return {
        ...initialState,
      };
    case SET_PROMPT:
      return {
        ...state,
        prompt: action.payload,
      };
    case UPDATE_TOTAL_PROMPT_TOKENS:
      return {
        ...state,
        formData: {
          ...state.formData,
          totalPromptTokens: action.payload,
        },
      };
    case UPDATE_TOTAL_COMPLETION_TOKENS:
      return {
        ...state,
        formData: {
          ...state.formData,
          totalCompletionTokens: action.payload,
        },
      };
    case EXPAND_INPUT_SUCCESS:
      return {
        ...state,
        formData: {
          ...state.formData,
          summaries: action.payload.result,
          promptTokens: action.payload.metadata.promptTokens,
          completionTokens: action.payload.metadata.completionTokens,
        },
      };
    case SAVE_SELECTED_SOURCES:
      return {
        ...state,
        formData: {
          ...state.formData,
          selected_sources: action.payload,
        },
      };
    case SAVE_SUMMARIES:
      return {
        ...state,
        formData: {
          ...state.formData,
          summaries: action.payload,
        },
      };
    case SAVE_MORE_INFO_ANSWERS:
      return {
        ...state,
        formData: {
          ...state.formData,
          more_info_answers: action.payload,
        },
      };
    case UPDATE_INTRO:
      return {
        ...state,
        formData: {
          ...state.formData,
          intro: action.payload,
        },
      };
    case UPDATE_TITLE:
      return {
        ...state,
        formData: {
          ...state.formData,
          title: action.payload,
        },
      };
    case GENERATE_SUMMARY_SUCCESS:
      return {
        ...state,
        generateSummaryResponse: action.payload,
      };

    case GET_SEARCH_WORDS_SUCCESS:
      return {
        ...state,
        formData: {
          ...state.formData,
          search_terms: action.payload.result,
          promptTokens: action.payload.metadata.promptTokens,
          completionTokens: action.payload.metadata.completionTokens,
        },
      };
    case POST_SEARCH_TERMS_SUCCESS:
      return {
        ...state,
        success: action.payload,
      };
    case POST_SEARCH_TERMS_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case POST_COARSE_FILTER_SUCCESS:
      return {
        ...state,
        success: action.payload,
      };
    case POST_COARSE_FILTER_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case GET_SEARCH_RESULTS_SUCCESS:
      return {
        ...state,
        formData: {
          ...state.formData,
          all_sources: action.payload.result,
          promptTokens: action.payload.metadata.promptTokens,
          completionTokens: action.payload.metadata.completionTokens,
        },
      };
    case GET_SEARCH_RESULTS_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case GET_COARSE_FILTER_SUCCESS:
      return {
        ...state,
        getCoarseFilterResponse: action.payload,
      };
    case GET_COARSE_FILTER_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case GET_MORE_INFO_SUCCESS:
      return {
        ...state,
        getMoreInfoResponse: action.payload,
        formData: {
          ...state.formData,
          more_info_questions: action.payload.result,
          promptTokens: action.payload.metadata.promptTokens,
          completionTokens: action.payload.metadata.completionTokens,
        },
      };
    case WRITE_TITLE_INTRO_SUCCESS:
      return {
        ...state,
        formData: {
          ...state.formData,
          title: action.payload.result[0].title,
          intro: action.payload.result[0].summary,
          promptTokens: action.payload.metadata.promptTokens,
          completionTokens: action.payload.metadata.completionTokens,
        },
      };
    case WRITE_ARTICLE_SUCCESS:
      return {
        ...state,
        formData: {
          ...state.formData,
          body: action.payload.result,
          promptTokens: action.payload.metadata.promptTokens,
          completionTokens: action.payload.metadata.completionTokens,
        },
      };
    case GET_OUTLINE_SUCCESS:
      return {
        ...state,
        formData: {
          ...state.formData,
          outline: action.payload,
        },
      };
    case GET_TITLE_SUCCESS:
      return {
        ...state,
        formData: {
          ...state.formData,
          title: action.payload,
        },
      };
    case POST_TITLE_SUCCESS:
      return {
        ...state,
        success: action.payload,
      };
    case POST_TITLE_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case POST_OUTLINE_SUCCESS:
      return {
        ...state,
        success: action.payload,
      };
    case POST_OUTLINE_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case GET_ARTICLE_BODY_SUCCESS:
      return {
        ...state,
        formData: {
          ...state.formData,
          body: action.payload,
        },
      };
    case GET_ARTICLE_HTML_SUCCESS:
      return {
        ...state,
        formData: {
          ...state.formData,
          html: action.payload,
        },
      };
    case GET_ARTICLES_SUCCESS:
      return {
        ...state,
        articles: action.payload,
      };
    case MOVE_NEXT_STEP:
      return {
        ...state,
        currentStep: state.currentStep + 1,
        formData: { ...state.formData, ...action.payload },
      };
    case MOVE_PREVIOUS_STEP:
      return {
        ...state,
        currentStep: state.currentStep - 1,
        formData: { ...state.formData, ...action.payload },
      };

    case UPDATE_SEARCH_TERMS:
      return {
        ...state,
        createSearchTermsResponse: {
          ...state.createSearchTermsResponse,
          search_terms: { search_terms: action.payload },
        },
      };
    case FETCH_ARTICLE_SUCCESS:
      return {
        ...state,
        article: action.payload,
        error: null,
      };
    case FETCH_ARTICLES_SUCCESS:
      return {
        ...state,
        articles: action.payload,
        error: null,
      };
    case FETCH_ARTICLES_FAIL:
    case FETCH_ARTICLE_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case SAVE_ARTICLE_SUCCESS:
      return {
        ...state,
      };
    case DELETE_ARTICLE_SUCCESS:
      // Filter out the deleted article from the state
      return {
        ...state,
        articles: state.articles.filter((article) => article.id !== payload),
        error: null, // Reset error state
      };
    case DELETE_ARTICLE_FAIL:
      return {
        ...state,
        error: payload, // Set error message
      };
    case SAVE_ARTICLE_FAIL:
    case GET_ARTICLE_BODY_FAIL:
    case GET_ARTICLE_HTML_FAIL:
    case GET_TITLE_FAIL:
    case GET_OUTLINE_FAIL:
    case GET_RESEARCH_REVIEW_FAIL:
    case CREATE_SEARCH_TERMS_FAIL:
    case SUBMIT_INPUT_FAIL:
    case EXPAND_INPUT_FAIL:
    case GET_INPUT_WITHOUT_URLS_FAIL:
    default:
      return state;
  }
}

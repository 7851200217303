import React from "react";
import { Container, ListGroup, Row, Col } from "react-bootstrap";

const HowItWorksSection = () => (
  <section id="how-it-works" className="how-it-works-section py-5 bg-light">
    <Container>
      <h2 className="text-center mb-5">How It Works</h2>
      <Row className="justify-content-center">
        <Col md={8}>
          <ListGroup as="ol" numbered className="">
            <ListGroup.Item
              as="li"
              className="shadow-sm d-flex justify-content-between align-items-start"
            >
              <div className="ms-2 me-auto">
                <div className="fw-bold">Input your topic or query</div>
              </div>
            </ListGroup.Item>
            <ListGroup.Item
              as="li"
              className="shadow-sm d-flex justify-content-between align-items-start"
            >
              <div className="ms-2 me-auto">
                <div className="fw-bold">
                  Laio assists with research, writing and source citation
                </div>
              </div>
            </ListGroup.Item>
            <ListGroup.Item
              as="li"
              className="shadow-sm d-flex justify-content-between align-items-start"
            >
              <div className="ms-2 me-auto">
                <div className="fw-bold">
                  Customize and finalize your content
                </div>
              </div>
            </ListGroup.Item>
            <ListGroup.Item
              as="li"
              className="shadow-sm d-flex justify-content-between align-items-start"
            >
              <div className="ms-2 me-auto">
                <div className="fw-bold">Publish with confidence</div>
              </div>
            </ListGroup.Item>
          </ListGroup>
        </Col>
      </Row>
    </Container>
  </section>
);

export default HowItWorksSection;

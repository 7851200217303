import axios from "axios";
import {
  CLEAR_MESSAGES,
  UPDATE_USER_PROFILE_FAIL,
  UPDATE_USER_PROFILE_SUCCESS,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAIL,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAIL,
  USER_ROLE_UPDATED,
  GET_PROFILES_SUCCESS,
  GET_PROFILES_FAILURE,
  FETCH_LANGUAGES_FAIL,
  FETCH_LANGUAGES_SUCCESS,
} from "./types";

export const updateProfile =
  (userId, first_name, last_name, email, language, current_profile) =>
  async (dispatch) => {
    const config = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify({
      first_name,
      last_name,
      email,
      language,
      current_profile,
    });

    try {
      const res = await axios.put(
        `${process.env.REACT_APP_API_URL}api/users/${userId}`,
        body,
        config
      );
      dispatch({
        type: UPDATE_USER_PROFILE_SUCCESS,
        payload: res.data,
        message: res.data.message,
      });
    } catch (err) {
      dispatch({
        type: UPDATE_USER_PROFILE_FAIL,
        payload: err.response ? err.response.data : err.message,
      });
    }
  };

export const ProfileClearMessages = () => ({
  type: CLEAR_MESSAGES,
});
export const userClearMessages = () => ({
  type: CLEAR_MESSAGES,
});

export const createUser = (email, organizationId) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({ email, organizationId });

  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}api/users/`,
      body,
      config
    );

    dispatch({
      type: CREATE_USER_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: CREATE_USER_FAIL,
      payload: err.response.data.message || "Failed to create user",
    });
  }
};

export const deleteUser = (userId) => async (dispatch) => {
  try {
    const res = await axios.delete(
      `${process.env.REACT_APP_API_URL}api/users/${userId}`
    );
    console.log("Response:", res);

    dispatch({
      type: DELETE_USER_SUCCESS,
      payload: userId,
      message: res.data.message,
    });
  } catch (err) {
    console.log("Error:", err);

    const errorMessage = err.response
      ? err.response.data.message
      : "Failed to delete user";
    dispatch({
      type: DELETE_USER_FAIL,
      payload: errorMessage,
    });
  }
};

export const changePassword =
  (id, oldPassword, newPassword) => async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify({ oldPassword, newPassword });

    try {
      const res = await axios.put(
        `${process.env.REACT_APP_API_URL}api/users/${id}/change-password`,
        body,
        config
      );

      dispatch({
        type: CHANGE_PASSWORD_SUCCESS,
        payload: res.data.message,
      });
    } catch (err) {
      let errorMessage = "An unknown error occurred.";
      if (err.response && err.response.data && err.response.data.message) {
        errorMessage = err.response.data.message;
      }
      dispatch({
        type: CHANGE_PASSWORD_FAIL,
        payload: errorMessage,
      });
    }
  };

export const updateUserRole = (userId, isAdmin) => async (dispatch) => {
  try {
    await axios.put(
      `${process.env.REACT_APP_API_URL}api/users/${userId}/change-role`,
      {
        isAdmin,
      }
    );
    dispatch({ type: USER_ROLE_UPDATED, payload: { userId, isAdmin } });
  } catch (error) {
    console.error("Error updating user role:", error);
  }
};

export const fetchProfiles = () => async (dispatch) => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_API_URL}api/profiles`);

    dispatch({
      type: GET_PROFILES_SUCCESS,
      payload: res.data.profiles,
    });
  } catch (err) {
    dispatch({
      type: GET_PROFILES_FAILURE,
      payload: err.response.data.message,
    });
  }
};

export const listLanguages = () => async (dispatch) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}api/users/languages`
    );

    dispatch({
      type: FETCH_LANGUAGES_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: FETCH_LANGUAGES_FAIL,
      payload: err.response.data.message,
    });
  }
};

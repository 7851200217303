// Import action types
import {
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAIL,
  GET_PROFILES_SUCCESS,
  GET_PROFILES_FAILURE,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAIL,
  UPDATE_USER_PROFILE_SUCCESS,
  UPDATE_USER_PROFILE_FAIL,
  FETCH_LANGUAGES_FAIL,
  FETCH_LANGUAGES_SUCCESS,
  CLEAR_MESSAGES,
} from "../actions/types";

// Initial state
const initialState = {
  error: null, // Variable to hold error messages
  successMessage: null,
  errorMessage: null,
  languages: null,
};

// Reducer function
export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_USER_PROFILE_SUCCESS:
      return {
        ...state,
        username: payload.username,
        first_name: payload.first_name,
        last_name: payload.last_name,
        email: payload.email,
        language: payload.language,
        current_profile: payload.current_profile,
        message: action.message,
      };
    case DELETE_USER_SUCCESS:
      // Filter out the deleted user from the state
      return {
        ...state,
        // users: state.users.filter((user) => user.id !== payload),
        error: null, // Reset error state
      };
    case DELETE_USER_FAIL:
      return {
        ...state,
        error: payload, // Set error message
      };
    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        message: payload,
        error: null,
      };
    case CHANGE_PASSWORD_FAIL:
      return {
        ...state,
        successMessage: null,
        errorMessage: payload,
      };
    case CREATE_USER_SUCCESS:
      return {
        ...state,
        successMessage: payload,
        errorMessage: null,
      };
    case CREATE_USER_FAIL:
      return {
        ...state,
        successMessage: null,
        errorMessage: payload,
      };
    case GET_PROFILES_SUCCESS:
      return {
        ...state,
        profiles: action.payload,
        error: null,
      };
    case FETCH_LANGUAGES_SUCCESS:
      return {
        ...state,
        languages: payload,
      };
    case CLEAR_MESSAGES:
      return {
        ...state,
        success: null,
        error: null,
        message: null,
      };
    case FETCH_LANGUAGES_FAIL:
    case UPDATE_USER_PROFILE_FAIL:
    case GET_PROFILES_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
}

import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer-section py-4 bg-footer-pattern bg-primary text-white">
      <Container>
        <Row className="align-items-center justify-content-center">
          <Col md={6} className="text-center">
            <p className="mb-0">
              &copy; {currentYear} Laio. All Rights Reserved.
            </p>
          </Col>
          {/* <Col md={6} className="text-center text-md-end mt-3 mt-md-0">
            <a href="/privacy" className="me-3 text-decoration-none">
              Privacy Policy
            </a>
            <a href="/contact" className="text-decoration-none">
              Contact Us
            </a>
          </Col> */}
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;

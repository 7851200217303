import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { movePreviousStep, saveArticle } from "../../actions/article";
import { Button } from "react-bootstrap";

const Article = () => {
  const dispatch = useDispatch();
  const formData = useSelector((state) => state.article.formData);
  const user = useSelector((state) => state.auth.user);
  const { language, current_profile, id } = user;
  // Now you can access the data in formData
  const {
    title,
    intro,
    body,
    prompt,
    search_terms,
    selected_sources,
    more_info_questions,
    more_info_answers,
    totalCompletionTokens,
    totalPromptTokens,
  } = formData;

  const handleSave = () => {
    dispatch(
      saveArticle(
        title,
        intro,
        body,
        id,
        current_profile,
        language,
        prompt,
        search_terms,
        selected_sources,
        more_info_questions,
        more_info_answers,
        totalCompletionTokens,
        totalPromptTokens
      )
    );
  };

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(
        `${title}\n${intro}\n${body
          .map(
            (paragraph) =>
              `${paragraph.paragraphtitle}\n${paragraph.paragraphtext}`
          )
          .join("\n")}`
      );
      alert("Article copied to clipboard!");
    } catch (error) {
      console.error("Failed to copy article to clipboard:", error);
    }
  };

  useEffect(() => {
    handleSave();
  }, []);

  return (
    <div className='step-content dashboard-input-wrapper step-6'>
      <div className='dashboard-input-container finished-article-body'>
        <button
          className='copy-button btn btn-primary'
          onClick={copyToClipboard}
        >
          Copy Article
        </button>
        <h1>{title}</h1>
        <div
          className='article-intro'
          dangerouslySetInnerHTML={{ __html: intro }}
        />
        {body.map((paragraph, index) => (
          <div key={index} className='article-paragraph'>
            <h2>{paragraph.paragraphtitle}</h2>
            <p dangerouslySetInnerHTML={{ __html: paragraph.paragraphtext }} />
          </div>
        ))}
        <div className='article-sources'>
          <h2>Sources:</h2>
          <ol>
            {Object.keys(body[body.length - 1]).map(
              (sourceKey, index) =>
                sourceKey.startsWith("source") && (
                  <li key={index}>
                    <a
                      href={body[body.length - 1][sourceKey]}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      {body[body.length - 1][sourceKey]}
                    </a>
                  </li>
                )
            )}
          </ol>
        </div>
      </div>
      <div className='step-nav'>
        <Button variant='primary' onClick={movePreviousStep}>
          Back
        </Button>
      </div>
    </div>
  );
};

export default Article;

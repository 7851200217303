import React from "react";
import { Route, Routes } from "react-router-dom";
import Sidebar from "./Sidebar";
import Settings from "./Settings";
import NewStory from "./NewStory";
import Admin from "./Admin";
import Organizations from "./Organizations";
import MyStories from "./MyStories";
import ArticlePage from "./ArticlePage"; // Import the ArticlePage component
import SuperAdmin from "./SuperAdmin";

const Dashboard = ({ user }) => {
  return (
    <div className='dashboard-wrapper'>
      <Sidebar user={user} />
      <div className='dashboard-container'>
        <Routes>
          <Route
            path='/'
            element={
              <div>
                <h3>Welcome to Laio</h3>
              </div>
            }
          />
          <Route path='new-story' element={<NewStory user={user} />} />
          <Route path='my-stories' element={<MyStories user={user} />} />
          <Route path='settings' element={<Settings user={user} />} />
          <Route path='admin' element={<Admin user={user} />} />
          <Route path='superadmin' element={<SuperAdmin user={user} />} />
          <Route
            path='admin/organization/:orgId'
            element={<Organizations user={user} />}
          />
          <Route path='article/:id' element={<ArticlePage />} />{" "}
          {/* New route for ArticlePage */}
        </Routes>
      </div>
    </div>
  );
};

export default Dashboard;
